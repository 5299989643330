import { computed, ref } from 'vue';
import type { VNode } from 'vue';

export type StringOrVNode =
  | string
  | VNode
  | (() => VNode);

export interface Alert {
  id: string;
  title: StringOrVNode;
  description: StringOrVNode;
  icon: StringOrVNode;
  content?: StringOrVNode;
  destructive?: boolean;
  type: 'info' | 'success' | 'error' | 'warning' | 'confirm';
  open: boolean;
  showCancelButton?: boolean;
  confirmButtonText?: string;
  cancelButtonText?: string;
  onOpenChange: ((value: boolean) => void) | undefined;
  onConfirm?: ((done?: () => void) => void | Promise<void>);
  onCancel?: ((done?: () => void) => void | Promise<void>);
};

const actionTypes = {
  ADD_ALERT: 'ADD_ALERT',
  DISMISS_ALERT: 'DISMISS_ALERT',
  REMOVE_ALERT: 'REMOVE_ALERT',
} as const;

let count = 0;

function genId() {
  count = (count + 1);
  return count.toString();
}

type ActionType = typeof actionTypes;

type Action =
  | { type: ActionType['ADD_ALERT']; alert: any }
  | { type: ActionType['DISMISS_ALERT']; alertId: string }
  | { type: ActionType['REMOVE_ALERT']; alertId: string };

interface State {
  alerts: Alert[];
}

const state = ref<State>({
  alerts: [],
});

const alertsToRemove = new Map<string, ReturnType<typeof setTimeout>>();

function addToRemoveQueue(alertId: string) {
  if (alertsToRemove.has(alertId))
    return;

  const timeout = setTimeout(() => {
    alertsToRemove.delete(alertId);
    dispatch({ type: actionTypes.REMOVE_ALERT, alertId });
  }, 1000);

  alertsToRemove.set(alertId, timeout);
}

function dispatch(action: Action) {
  switch (action.type) {
    case actionTypes.ADD_ALERT:
      state.value.alerts = [action.alert, ...state.value.alerts];
      break;
    case actionTypes.DISMISS_ALERT:
      addToRemoveQueue(action.alertId);

      state.value.alerts = state.value.alerts.map(a =>
        a.id === action.alertId
          ? ({ ...a, open: false })
          : a,
      );
      break;
    case actionTypes.REMOVE_ALERT:
      state.value.alerts = state.value.alerts.filter(a => a.id !== action.alertId);
      break;
  }
}

function useAlert() {
  return {
    alerts: computed(() => state.value.alerts),
    alert,
    confirm,
    info,
    error,
    success,
    warning,
    dismiss: (alertId: string) => dispatch({ type: actionTypes.DISMISS_ALERT, alertId }),
  };
}

function alert(props: Omit<Alert, 'id' | 'open' | 'onOpenChange'>) {
  const id = genId();

  const dismiss = () => dispatch({ type: actionTypes.DISMISS_ALERT, alertId: id });

  return new Promise((resolve, reject) => {
    const { onConfirm, onCancel } = props;

    dispatch({
      type: actionTypes.ADD_ALERT,
      alert: {
        ...props,
        onConfirm: () => {
          if (!onConfirm)
            return resolve(dismiss);

          onConfirm();
          return resolve(dismiss);
        },
        onCancel: () => {
          if (!onCancel)
            return reject(dismiss);
        },
        id,
        open: true,
        onOpenChange: (open: boolean) => {
          if (!open)
            dismiss();
        },
      },
    });
  });
}

type ShortcutAlertProp = Partial<Omit<Alert, 'id' | 'type' | 'open' | 'onOpenChange' | 'icon' | 'type'>>;

function confirm(props: ShortcutAlertProp) {
  return alert({
    ...props,
    type: 'confirm',
    icon: 'icon-[ph--question]',
    showCancelButton: true,
  });
}

function error(props: ShortcutAlertProp) {
  return alert({
    ...props,
    type: 'error',
    destructive: true,
    icon: 'icon-[ph--x-circle]',
  });
}

function warning(props: Omit<Alert, 'id' | 'type' | 'open' | 'onOpenChange'>) {
  return alert({
    ...props,
    type: 'warning',
    icon: 'icon-[ph--warning]',
  });
}

function info(props: Omit<Alert, 'id' | 'type' | 'open' | 'onOpenChange'>) {
  return alert({
    ...props,
    type: 'info',
    icon: 'icon-[ph--info]',
  });
}

function success(props: Omit<Alert, 'id' | 'type' | 'open' | 'onOpenChange'>) {
  return alert({
    ...props,
    type: 'success',
    icon: 'icon-[ph--check-circle]',
  });
}

export { alert, confirm, error, info, warning, success, useAlert };
