<script setup lang="ts">
import { isVNode } from 'vue';
import type { Alert } from '@/composables/use-alert';

const { alerts, dismiss } = useAlert();

function onHandleCancel(alert: Alert) {
  if (!alert.onCancel)
    dismiss(alert.id);

  alert.onCancel();
}

function onHandleConfirm(alert: Alert) {
  if (!alert.onConfirm)
    dismiss(alert.id);

  alert.onConfirm();
}
</script>

<template>
  <slot />

  <UIAlertDialogRoot v-for="alert in alerts" :key="alert.id" :open="alert.open" @update:open="alert.onOpenChange">
    <UIAlertDialogPortal>
      <UIAlertDialogOverlay class="fixed inset-0 z-50 bg-black/60 backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0" />
      <UIAlertDialogContent class="fixed left-1/2 top-1/2 z-50 grid -translate-x-1/2 -translate-y-1/2 flex-col gap-4 rounded-lg bg-white p-8 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%]">
        <div class="flex items-center gap-2">
          <span
            v-if="alert.icon"
            :class="cn('size-6', alert.icon, {
              'text-primary': ['info', 'confirm'].includes(alert.type),
              'text-success': alert.type === 'success',
              'text-error': alert.type === 'error',
              'text-warning': alert.type === 'warning',
            })"
          />

          <UIAlertDialogTitle v-if="isVNode(alert.title)">
            <component :is="alert.title" />
          </UIAlertDialogTitle>
          <UIAlertDialogTitle v-else class="text-xl">
            {{ alert.title }}
          </UIAlertDialogTitle>
        </div>

        <UIAlertDialogDescription v-if="isVNode(alert.description)">
          <component :is="alert.description" />
        </UIAlertDialogDescription>
        <UIAlertDialogDescription v-else class="text-neutral-2">
          {{ alert.description }}
        </UIAlertDialogDescription>

        <component :is="alert.content" />

        <div class="flex items-center justify-end gap-2">
          <UIAlertDialogCancel v-if="alert.showCancelButton" class="rounded px-4 py-2 font-medium" @click.prevent="onHandleCancel(alert)">
            {{ alert.cancelButtonText || $t('common.cancel') }}
          </UIAlertDialogCancel>
          <UIAlertDialogAction
            :class="cn('rounded px-4 py-2 font-medium text-white',
                       alert.destructive || alert.type === 'error'
                         ? 'bg-error'
                         : {
                           'bg-primary': ['info', 'confirm'].includes(alert.type),
                           'bg-warning': alert.type === 'warning',
                           'bg-success': alert.type === 'success',
                         },
            )"
            @click.prevent="onHandleConfirm(alert)"
          >
            {{ alert.confirmButtonText || $t('common.ok') }}
          </UIAlertDialogAction>
        </div>
      </UIAlertDialogContent>
    </UIAlertDialogPortal>
  </UIAlertDialogRoot>
</template>
