<script setup lang="ts">
import { VueQueryDevtools } from '@tanstack/vue-query-devtools';
import { watch } from 'vue';
import AlertProvider from '@/providers/AlertProvider.vue';

useHead({
  htmlAttrs: {
    lang: 'en',
  },
  title: 'Workstate',
  titleTemplate: title => title !== 'Workstate' ? `${title} - Workstate` : title,
});

const { setLocale } = useI18n();

const router = useRouter();
const { loggedIn, state, user } = useAuth();

const { $toasty } = useNuxtApp();

const isOnline = useOnline();
const [showLoader, setShowLoader] = useToggle(true);

watch(
  unref(router.currentRoute)?.fullPath,
  () => {
    if (unref(router.currentRoute)?.meta?.auth === 'guest') {
      setShowLoader(
        !['idle', 'login', 'loggedOut', 'tfaVerify'].includes(state.value?.value as string ?? ''),
      );
    }
    else {
      setShowLoader(!loggedIn.value);
    }
  },
  { immediate: true },
);

watch(state, () => {
  setShowLoader(
    !['idle', 'login', 'loggedOut', 'tfaVerify'].includes(state.value?.value as string ?? '')
    && !loggedIn.value,
  );
});

watch(isOnline, () => {
  if (isOnline.value) {
    $toasty.$success({
      title: 'Connection established',
      message: 'You are now online.',
    });
  }
  else {
    $toasty.$error({
      title: 'Connection lost',
      message: 'You are now offline.',
    });
  }
});

watchEffect(async () => {
  if (loggedIn.value)
    await setLocale(user.value.language);
});
</script>

<template>
  <UITooltipProvider>
    <AlertProvider>
      <TransitionGroup
        enter-active-class="transition-opacity duration-150 ease-in-out"
        leave-active-class="transition-opacity duration-150 ease-in-out"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
      >
        <div v-if="showLoader" class="fixed inset-0 flex size-full items-center justify-center bg-neutral-3">
          <Loader class="text-primary" />
        </div>

        <NuxtLayout v-else>
          <NuxtPage />
        </NuxtLayout>
      </TransitionGroup>
    </AlertProvider>
  </UITooltipProvider>
  <VueQueryDevtools />
</template>
